body{
    background-color: unset;
    margin: 0;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f1f1f1;
}

.paymentIni{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.cardContainer{
    display: flex;
    margin: 2rem auto;
    flex-direction: column;
    padding: 1.5rem 2rem;
    box-sizing: border-box;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0 0 10px #00000080;
    width: 900px;
    background-image: linear-gradient(to bottom, #75384F 15%, #4C4369, #164587, #00295B);
}

.memInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.memInfo .memType{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
    font-size: 18px;
    color: #fff;
}
.memInfo .cardName{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: #fff;
}

.designContainer{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}
.designContainer .uniinov{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 900;
    color: #ffffff;
    text-shadow: 0 0 20px rgba(0,0,0,0.53);
    z-index: 5;
    position: absolute;
    font-size: 72px;
    bottom: 3rem;
}

.designContainer img{
    z-index: 0;
    width: 90%;
    height: 175px;
}
.cardInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 2rem auto 0 auto;
}
.cardInfo .cardInfoRow, .cardInfo .cardInfoColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.4rem 0;
    width: 80%;
}
.cardInfo .cardInfoRow .cardInfoCat, .cardInfo .cardInfoColumn .cardInfoCat {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    width: max-content;
    margin-right: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.cardInfo .cardInfoRow .catField, .cardInfo .cardInfoColumn .catField {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    color: #aaa;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 900;
    font-size: 24px;
    width: 81.45%;
}
.cardInfo .cardInfoRow .cardInfoColumn .catField, .cardInfo .cardInfoColumn .cardInfoColumn .catField {
    width: 100%;
}
.cardInfo .cardInfoRow .cardInfoColumn:nth-child(1), .cardInfo .cardInfoColumn .cardInfoColumn:nth-child(1) {
    margin-right: 20px;
}
.cardInfo .cardInfoRow .cardInfoColumn:nth-child(1) .cardInfoCat, .cardInfo .cardInfoColumn .cardInfoColumn:nth-child(1) .cardInfoCat {
    width: 60%;
}
.cardInfo .cardInfoRow .cardInfoColumn:nth-child(2), .cardInfo .cardInfoColumn .cardInfoColumn:nth-child(2) {
    margin-left: 10px;
}
.cardInfo .cardInfoRow .cardInfoColumn:nth-child(2) .cardInfoCat, .cardInfo .cardInfoColumn .cardInfoColumn:nth-child(2) .cardInfoCat {
    width: 70%;
}
