 .box {
    cursor: pointer; 
    padding: 50px 20px;
    margin-bottom: 50px;
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 15px;    
    height: 180px;
    position: relative;
    background: #fafafa;
    transition: all ease-in 0.2s;    ;
  }
  
   .icon {
    position: absolute;
    top: -36px;
    left: calc(50% - 36px);
    transition: 0.2s;
    border-radius: 50%;
    border: 6px solid #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 72px;
    height: 72px;
    background: #28a796;
  }
  
 .icon a {
    display: inline-block;
  }
  
 .icon i {
    color: #fff;
    font-size: 24px;
  }
  
  
 .box:hover{
    box-shadow: 5px 10px #e6e6e6; 
    border:2px solid #3aafa9;    
  }
  
 .box:hover .icon {
    background: #fff;
    border: 2px solid #3aafa9;
  }
  
 .box:hover .icon i {
    color: #3aafa9;
  }
  
 .box:hover .icon a {
    color: #3aafa9;
  }
  
.title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
.title a {
    color: #111;
    transition : all 0.3s ease-in;
  }

.title a:hover {
    color: #3aafa9;
  }  
  
.description {
    font-size: 14px;
    line-height: 24px;
    color : black;
    text-align: justify;
  }