.logo{
    height: 60px;
    width: 130px;
    margin: -5px -10px -2px -65px;
}
.logo2{
    height: 60px;
    max-width: 100%;
    margin: -5px -10px -2px 8px;
}
.text{
    font-size: 12px;
    margin-top: -5px;
}

@media only screen and (max-width: 600px) {
    .logo{
     margin-left: 30px
    }
  }
